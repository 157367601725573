<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="3" class="">
            <label>Mostrar</label>
            <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>registros</label>
          </b-col>
          <b-col cols="12" md="9">
            <div class="d-flex align-items-center justify-content-end">
              <b-col md="4" class="offset-8">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Buscar" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Borrar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-button variant="primary" class="btn-icon rounded-circle mr-1" @click="(store = true, resetFormStore())"
                v-if="permissions.includes('modalities.store')">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refRoleListTable" class="position-relative" :items="dataTable" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
        :sort-desc.sync="tableSettings.sortDesc" :filter="filter" :filter-included-fields="filterOn"
        @filtered="onFiltered">
        <template #cell(status)="data">
          <b-badge variant="success" v-if="(data.item.status == 1)">
            Activo
          </b-badge>
          <b-badge variant="danger" v-if="(data.item.status == 0)">
            Inactivo
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button @click="showModality(data.item.id)" variant="primary" class="btn-icon rounded-circle ml-2"
            v-b-tooltip.hover.v-primary title="Ver" v-if="permissions.includes('modalities.show')">
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button @click="openModalityEdit(data.item.id)" class="btn-icon rounded-circle ml-2"
            v-b-tooltip.hover.v-primary title="Editar" variant="warning" v-if="permissions.includes('modalities.update')">
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button @click="deleteData(data.item)" variant="danger" class="btn-icon rounded-circle ml-2"
            v-b-tooltip.hover.v-primary title="Eliminar" v-if="permissions.includes('modalities.delete')">
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }}
              registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="tableSettings.page" :total-rows="totalRows" :per-page="tableSettings.perPage"
              first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>


    <!-- modal Crear Modalidad -->
    <b-modal hide-footer title="Crear Modalidad" v-model="store">
      <validation-observer ref="formStore">
        <b-form>
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Modalidad" rules="required">
                <b-form-group>
                  <label for="modalidad">Modalidad</label>
                  <b-form-input v-model="formStoreModality.modality" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Maximo De Personas" rules="required">
                <b-form-group>
                  <label>Maximo De Personas</label>
                  <b-form-input type="number" v-model="formStoreModality.max_people" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Minimo De Personas" rules="required">
                <b-form-group>
                  <label for="Minimo De Personas">Minimo De Personas</label>
                  <b-form-input type="number" v-model="formStoreModality.min_people" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Rol" rules="required">
                <b-form-group>
                  <label>Status</label>
                  <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="formStoreModality.status"
                    label="text" :options="status" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="12" class="mb-1 text-center">
              <b-button @click="validationForm()" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                Crear
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- modal Crear Modalidad -->
    <!-- modal Ver Modalidad -->
    <b-modal hide-footer title="Ver Modalidad" v-model="show">
      <validation-observer ref="formStore">
        <b-form>
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Modalidad" rules="required">
                <b-form-group>
                  <label for="modalidad">Modalidad</label>
                  <b-form-input v-model="form.modality" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Maximo De Personas" rules="required">
                <b-form-group>
                  <label>Maximo De Personas</label>
                  <b-form-input type="number" v-model="form.max_people" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Minimo De Personas" rules="required">
                <b-form-group>
                  <label for="Minimo De Personas">Minimo De Personas</label>
                  <b-form-input type="number" v-model="form.min_people" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Rol" rules="required">
                <b-form-group>
                  <label>Status</label>
                  <b-form-input v-if="form.status == 1" value="Activo" disabled />
                  <b-form-input v-else value="Inactivo" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- modal Ver Modalidad -->
    <!-- modal Editar Modalidad -->
    <b-modal hide-footer title="Editar Modalidad" v-model="edit">
      <validation-observer ref="formUpdate">
        <b-form>
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Modalidad" rules="required">
                <b-form-group>
                  <label for="modalidad">Modalidad</label>
                  <b-form-input v-model="form.modality" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Maximo De Personas" rules="required">
                <b-form-group>
                  <label>Maximo De Personas</label>
                  <b-form-input type="number" v-model="form.max_people" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Minimo De Personas" rules="required">
                <b-form-group>
                  <label for="Minimo De Personas">Minimo De Personas</label>
                  <b-form-input type="number" v-model="form.min_people" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider #default="{ errors }" name="Rol" rules="required">
                <b-form-group>
                  <label>Status</label>
                  <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="form.status" label="text"
                    :options="status" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="12" class="mb-1 text-center">
              <b-button @click="validationFormUpdate()" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                Actualizar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- modal Editar Modalidad -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BForm, BFormGroup, BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { extend } from 'vee-validate'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import es from 'vee-validate/dist/locale/es'
export default {
  name: 'Role',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VBTooltip,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      filter: null,
      filterOn: [],
      permissions: [],
      formStoreModality: {
        modality: '',
        max_people: '',
        min_people: '',
        status: ''
      },
      form: {
        modality: '',
        max_people: '',
        min_people: '',
        status: ''
      },
      status: [
        { 'value': '1', 'text': 'Activo' },
        { 'value': '0', 'text': 'Inactivo' },
      ],
      store: false,
      show: false,
      edit: false,
      formDisabled: false,
      refRoleListTable: null,
      perPageOptions: [25, 50, 100],
      searchQuery: '',
      inputs: [
        { value: 'id', title: 'Id' },
        { value: 'name', title: 'Modalidad' },
        { value: 'people_max', title: 'Max Personas' },
        { value: 'people_min', title: 'Min Personas' },
      ],
      typeSearch: [
        { value: 'LIKE', title: 'Igual' },
        { value: 'NOT LIKE', title: 'No es igual' },
        { value: '>', title: 'Mayor que' },
        { value: '<', title: 'Menor que' }
      ],
      tableColumns: [
        { key: 'name', label: 'Modalidad', sortable: true },
        { key: 'people_max', label: 'Maximo De Personas', sortable: true },
        { key: 'people_min', label: 'Minimo De Personas', sortable: true },
        { key: 'status', label: 'Estado', sortable: true },
        { key: 'actions', label: 'acciones' },
      ],
      sortBy: 'id',
      isSortDirDesc: true,
      totalRows: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      dataTable: [],
      showLoadingTable: false,
      tableSettings: {
        filter: this.$route.params && this.$route.params.id,
        searchQuery: '',
        input: '',
        typeSearch: '',
        perPage: 25,
        page: 1,
        sortBy: 'id',
        sortDesc: true,
      },
      formDataEdit: {},
      modalitiy_id: ''
    }
  },
  watch: {
    "tableSettings.sortBy": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.sortDesc": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.perPage": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.searchQuery": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.page": {
      handler(val) {
        this.fetchData()
      },
    },
  },
  created() {
    this.fetchData()
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.permissions = this.userData.permissions
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    resetFormStore() {
      this.formStoreModality = {
        modality: '',
        max_people: '',
        min_people: '',
        status: ''
      }
    },
    validationForm() {
      localize('es', es);
      this.$refs.formStore.validate().then(success => {
        if (success) {
          this.storeModality();
        }
      })
    },
    validationFormUpdate() {
      localize('es', es);
      this.$refs.formUpdate.validate().then(success => {
        if (success) {
          this.updateModality();
        }
      })
    },
    storeModality() {
      this.$http.post('/modalities/store', this.formStoreModality)
        .then(response => {
          if (response.data.code == 200) {
            this.$swal({
              title: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
            this.fetchData();
            this.store = false;
            this.formStoreModality = {
              modality: '',
              max_people: '',
              min_people: '',
              status: ''
            }
          }
          if (response.data.code == 500) {
            this.$swal({
              title: response.data.message,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    updateModality() {
      this.$http.post('/modalities/update/' + this.modalitiy_id, this.form)
        .then(response => {
          if (response.data.code == 200) {
            this.$swal({
              title: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
            this.fetchData();
            this.edit = false;
            this.form = {
              modality: '',
              max_people: '',
              min_people: '',
              status: ''
            }
          }
          if (response.data.code == 500) {
            this.$swal({
              title: response.data.message,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    fetchData() {
      this.showLoadingTable = true
      this.$http.get('/modalities/list', { params: this.tableSettings }).then((response) => {
        this.dataTable = response.data.modalities
        this.totalRows = response.data.total
        this.dataMetaCounter()
        this.showLoadingTable = false
      })
    },
    dataMetaCounter() {
      const localItemsCount = this.dataTable.length
      this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
      this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
      this.dataMeta.of = this.totalRows
    },
    detailData(row) {
      this.formDisabled = true
      this.formDataEdit = row
    },
    editData(row) {
      this.formDataEdit = row
    },
    deleteData(row) {
      this.$swal({
        title: `Esta séguro de eliminar la modalidad ${row.name}?`,
        text: "Este proceso no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post('/modalities/delete/' + row.id).then(() => {
            this.fetchData()
            this.$swal({
              icon: 'success',
              title: 'Plan eliminado!',
              text: 'El plan ha sido eliminado.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Eliminación cancelada',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showModality(id) {
      this.show = true;
      this.$http.get('/modalities/show/' + id).then(response => {
        this.form.modality = response.data.modality.name;
        this.form.max_people = response.data.modality.people_max;
        this.form.min_people = response.data.modality.people_min;
        this.form.status = response.data.modality.status;
      })
    },
    openModalityEdit(id) {
      this.edit = true;
      this.modalitiy_id = id;
      this.$http.get('/modalities/show/' + id).then(response => {
        this.form.modality = response.data.modality.name;
        this.form.max_people = response.data.modality.people_max;
        this.form.min_people = response.data.modality.people_min;
        if (response.data.modality.status == 1) {
          this.form.status = [
            { 'value': '1', 'text': 'Activo' }
          ]
        } else {
          this.form.status = [
            { 'value': '2', 'text': 'Inactivo' }
          ]
        }
      })
    }
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
